import { CardsList } from 'libs/brand-system/src/templates/CardsList';
import { Section } from 'libs/growth-platform-brand-system-v2/src/components/Section';
import { container } from 'libs/growth-platform-brand-system-v2/src/styles/themes';
import { HeroBlog } from 'libs/growth-platform-brand-system-v2/src/templates/HeroBlog';
import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { graphql } from 'gatsby';
import { morphism } from 'morphism';
import React from 'react';
import { TemplateArticleZone } from '../../components/TemplateArticleZone';
import Layout from '../../layout';
import {
  HeadWrapper,
  largestContentfullPaintImg,
} from '../../layout/HeadWrapper';
import { schemaHeroBlog } from '../../morphismSchema/templates/schemaHeroBlog';
import { getLastAticlesCards } from '../../utils/getLastAticlesCards';
import { cardsListTitle } from '../../utils/utils';

export const Head = ({ data, pageContext, location }: any) => {
  const heroProps = data?.datoCmsNewAboutPayfit?.hero?.[0];
  const largestContentfullPaintImg: largestContentfullPaintImg =
    heroProps?.image?.gatsbyImageData?.images?.fallback || null;
  return (
    <HeadWrapper
      data={data}
      pageContext={pageContext}
      location={location}
      templateName="NewAboutPayfitPage"
      dataName={'datoCmsNewAboutPayfit' as any}
      largestContentfullPaintImg={largestContentfullPaintImg}
    />
  );
};
export const query = graphql`
  query DatoNewFAboutPayfitPage($locale: String, $id: String, $slug: String) {
    datoCmsNewAboutPayfit(locale: $locale, id: { eq: $id }) {
      ...NewAboutPayfitPage
    }
    allDatoCmsNewAboutPayfit(
      limit: 3
      locale: $locale
      filter: { slug: { nin: [null, $slug] }, locales: { eq: $locale } }
      sort: { _allDateLocales: { value: DESC } }
    ) {
      nodes {
        id
        slug
        date
        hero {
          image {
            url
            alt
            gatsbyImageData(
              width: 446
              breakpoints: [480, 768, 1024, 1280]
              imgixParams: { w: "446", dpr: 1, q: 90 }
            )
            width
            height
          }
        }
        metaFields {
          description
          title
        }
      }
    }
  }
`;

const NewAboutPayfitPage = ({ data, pageContext }: any) => {
  const doc: any = data.datoCmsNewAboutPayfit;
  const screenSize = useScreenSize();
  const lastArticles = data.allDatoCmsNewAboutPayfit?.nodes;
  const hero = doc.hero[0];
  // generate tracking page name from document.title
  doc.trackingPageName = {
    title: doc?.metaFields?.title,
  };

  const lastArticlesCards = getLastAticlesCards({
    lastArticles,
    screenSize: screenSize,
    locale: pageContext.locale,
  });

  return (
    <Layout
      pageContext={pageContext}
      data={doc}
      templateName="NewAboutPayfitPage"
    >
      <HeroBlog
        {...morphism(schemaHeroBlog, {
          ...hero,
          filterTags: hero?.filterTags?.map((tag: any, i: number) => ({
            ...tag,
            key: i,
            size: 'small',
            type: 'outlined',
            color: 'blue',
            isDark: true,
          })),
        })}
        updateTime={new Date(doc?.date)}
        className={classNames('mx-24 md:mx-auto mt-[160px]', container)}
      />
      <TemplateArticleZone doc={doc} isNewFeature locale={pageContext.locale} />
      {lastArticles?.length > 0 && (
        <Section backgroundColor="blue">
          <CardsList
            id={'last-articles'}
            title={cardsListTitle[pageContext.locale]}
            cards={lastArticlesCards}
            backgroundColor="blue"
            cardsContainerClass="-mx-12"
            shouldDisplay={3}
          />
        </Section>
      )}
    </Layout>
  );
};

export default NewAboutPayfitPage;
